class LqiHelper {
  static renderLQIRating(value) {
    if (value === null || isNaN(value) || value <= 200) {
      return 'red';
    }
    if (value > 200 && value <= 230) {
      return 'yellow';
    }
    if (value > 230) {
      return 'green';
    }
  }
}

export default LqiHelper;
