import { GET_USER_ME, UNAUTH_USER, CLEAR_USER_ME, CURRENT_GROUPING_CHANGED } from './types';

import { UsersService } from '../services';
import i18next from "i18next";

export function getUserMe() {
  return (dispatch) => {
    UsersService.getMe().then(response => {
      const adhocRoleAssignment = response.me.roles.filter(role => role.roleType.token === "access_role").length > 1;
      let me = response.me;
      me['adhocRoleAssignment'] = adhocRoleAssignment;
      dispatch({ type: GET_USER_ME, payload: me });
      if (response.me.availableGroupings && response.me.availableGroupings.length > 0) {
        dispatch({ type: CURRENT_GROUPING_CHANGED, payload: response.me.availableGroupings[0] });
      } else {
        console.error('The user has no available groupings, this will be an problem. Logging the user out.');
        clearUserMe();
        dispatch({ type: UNAUTH_USER });
      }
      i18next.changeLanguage(response.me.locale.languageTag);
    }).catch(error => {
      console.error('Could not get user profile from server.', error);
      dispatch({ type: UNAUTH_USER });
    });
  };
}

export function clearUserMe() {
  return (dispatch) => {
    dispatch({ type: CLEAR_USER_ME });
    dispatch({ type: CURRENT_GROUPING_CHANGED, payload: null });
  }
}
