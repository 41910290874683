import { CURRENT_GROUPING_CHANGED } from '../actions/types';

export default function groupingReducer(state = { currentGrouping: null }, action) {
  switch (action.type) {
    case CURRENT_GROUPING_CHANGED:
      return { ...state, currentGrouping: action.payload };
    default:
      return state;
  }
}
