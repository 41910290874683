import i18next from 'i18next';
import RssiHelper from './rssi_helper';

class FormatHelper {
    static formatMeasurement(threshold, datapoint) {
        let evaluationValue;

        if (datapoint) {
            evaluationValue = datapoint.numericValue != null ? datapoint.numericValue : datapoint.stringValue;
        } else {
            evaluationValue = threshold.displayableEvaluationNumericValue != null ? threshold.displayableEvaluationNumericValue : threshold.displayableEvaluationStringValue;
        }

        if (threshold.deviceDataType.token === 'rssi') {
          return `${evaluationValue} (${RssiHelper.renderRSSIValue(evaluationValue)})`;
        } else if (threshold.deviceDataType.token === 'magnetometer') {
          return i18next.t(`magnetometer-data-string-display.${evaluationValue}`);
        } else if (threshold.deviceDataType.token === 'door_state') {
          return  i18next.t(`door-state-data-string-display.${evaluationValue}`);
        } else if (threshold.deviceDataType.token === 'power_status') {
          return i18next.t(`power-status-data-string-display.${evaluationValue}`); 
        } else if (threshold.deviceDataType.deviceDataDisplayType && threshold.deviceDataType.deviceDataDisplayType.format) {
          return sprintf(threshold.deviceDataType.deviceDataDisplayType.format, evaluationValue);
        }

        return evaluationValue;
    }

    static formatPhoneNumbers(preferences, property="sms") {
      preferences.forEach(pref => {
        if (pref[property] !== '+1') {
          pref[property] = this.formatPhoneNumber(pref[property]);
        }
      });
      return preferences;
    }

  static formatPhoneNumber(phone) {
    if (phone.charAt(0) === '+') {
      return phone.charAt(1) + phone.substring(phone.indexOf('(') + 1, phone.indexOf(')'))
      + phone.substring(phone.indexOf(')') + 2, phone.indexOf('-')) + phone.substring(phone.indexOf('-') + 1);
    }
    return phone;
    }
}

export default FormatHelper;