import { GET_USER_ME, CLEAR_USER_ME } from '../actions/types';

export default function userReducer(state = { me: null }, action) {
  switch (action.type) {
    case GET_USER_ME:
      return { ...state, me: action.payload };
    case CLEAR_USER_ME:
      return { ...state, me: null };
    default:
      return state;
  }
}
