import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import authReducer from './auth_reducer';
import userReducer from './user_reducer';
import groupingReducer from './grouping_reducer';
import { reducer as uiReducer } from 'redux-ui';

const rootReducer = combineReducers({
  form,
  auth: authReducer,
  user: userReducer,
  ui: uiReducer,
  currentGrouping: groupingReducer
});

export default rootReducer;
