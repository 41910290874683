import i18next from "i18next";
class RssiHelper {
  static LAIRD_LORA_DEVICE_TYPE = 'lairdrs1xx';

  static renderRSSIValue(value, deviceTypeToken) {
    if (value === null || isNaN(value))
      return i18next.t('unknown')

    if (deviceTypeToken && deviceTypeToken === this.LAIRD_LORA_DEVICE_TYPE) {
      if (value >= -30)
        return i18next.t('good')
      if (value > -120)
        return i18next.t('fair')
      if (value > -130)
        return i18next.t('poor')
    } else {
      if (value > -70)
        return i18next.t('good')
      if (value > -80)
        return i18next.t('fair')
      if (value > -90)
        return i18next.t('poor')
    }
    return i18next.t('offline')
  }

  static renderRSSIRating(value, deviceTypeToken) {
    if (value === null || isNaN(value)) {
      return 'red';
    }

    if (deviceTypeToken && deviceTypeToken === this.LAIRD_LORA_DEVICE_TYPE) {
      if (value >= -30)
        return 'green'
      if (value > -120)
        return 'yellow'
      if (value <= -120)
        return 'red'
    } else {
      if (value > -70) 
        return 'green';
      if (value > -80)
        return 'yellow';
      if (value <= -80) 
        return 'red';
    }    
  }
}

export default RssiHelper;
