import { HologramService } from '../services';


class HologramHelper {
    static TAG_ID_TOKEN = 'hologram_tag_id';

    static getHologramTag(enterprise) {
        let hologramTag = enterprise.hologramMetadata.find(metadata => metadata.token === this.TAG_ID_TOKEN);
        if (hologramTag) {
            return hologramTag.numericValue;
        } else {
            HologramService.getDeviceTags().then((response) => {
                hologramTag = response.data.tags.find(tag => tag.name === enterprise.token);

                if (hologramTag && hologramTag.id) {
                    this.cacheHologramTagForEnterprise(hologramTag.id, enterprise.id);
                }

                return hologramTag ? hologramTag.id : null;
            });
        }
}

    static cacheHologramTagForEnterprise(tagId, enterpriseId) {
        const tagMetadata = {
            token: this.TAG_ID_TOKEN,
            name: 'Hologram Tag ID',
            numericValue: tagId,
            enterprise: {id: enterpriseId}
        }

        HologramService.cacheHologramTag(tagMetadata).catch((err) => {
            console.error(err);
        });
    }
}

export default HologramHelper;