import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import translationEN from '../public/translations-dictionary/en-US.json';
import translationFR from '../public/translations-dictionary/fr.json';
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  }
};
i18n
  .use(LngDetector)
  .init({
    resources,
    fallbackLng: 'en',
    //debug: true,
    preload: ['en-US', 'fr'],
    detection: { checkForSimilarInWhitelist: true }
  });

export default i18n;