class ValidationHelper {
    static validatePhoneNumber(value) {
       return value && value.length > 2 && !/^(1?\d{10,11})|([0-9]{3}\)([0-9]{3}-)[0-9]{3}-[0-9]{4})|(.{17,19})$/i.test(value) ? 'Invalid phone number' : undefined;
    }

    static validateEmail(value) {
        return value && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value) ? 'Invalid email address' : undefined;
    }

    static validateUsername(value) {
        return value && /^([A-Za-z]{1})([A-Za-z0-9.@_-]{6,})$/.test(value);
    }
} 

export default ValidationHelper;