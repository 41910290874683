class AccessHelper {
    static userHasAccess(availableRoutes, routePath) {
        let accessGranted = false;

        Object.keys(availableRoutes).forEach(key => {
            accessGranted = accessGranted ? accessGranted : this.checkRoutes(availableRoutes[key], routePath);
        });

        return accessGranted;
    }

    static checkRoutes(routes, routePath) {
        let routeFound = false;

        for (let i = 0; i < routes.length; i++) {
            let route = routes[i];
            if (routePath.startsWith(route.path)) {
                if (route.path === routePath) {
                    routeFound = true;
                    break;
                } else if (route.children.length > 0) {
                    routeFound = this.checkRoutes(route.children, routePath);
                }
            }
        }

        return routeFound;
    }
}

export default AccessHelper;