
import _ from 'lodash';
import { ReactTableDefaults } from 'react-table';

class BaseTable {
  static buildURLOptions(tableData, defaultSorted) {
    const options = [];

    if (tableData) {
      if (tableData.pageSize && tableData.pageSize !== ReactTableDefaults.defaultPageSize) {
        options.push({ size: tableData.pageSize });
      }

      if (tableData.page) {
        options.push({ page: tableData.page });
      }

      if (tableData.sorted && !_.isEqual(tableData.sorted, defaultSorted)) {
        tableData.sorted.forEach((order) => {
          options.push({ sort: `${order.id},${order.desc ? 'desc' : 'asc'}` });
        });
      }
    }

    return options;
  }

  static buildFetchDataOptions(parsedOptions, defaultSorted) {
    const options = [];
    let containsPageSize = false;
    let containsSort = false;
    Object.entries(parsedOptions).forEach(([k, v]) => {
      const entry = {};
      entry[k] = v;
      options.push(entry);

      if (k === 'size') containsPageSize = true;
      if (k === 'sort') containsSort = true;
    });

    if (!containsPageSize) {
      options.push({ size: ReactTableDefaults.defaultPageSize });
    }

    if (!containsSort) {
      defaultSorted.forEach((order) => {
        options.push({ sort: `${order.id},${order.desc ? 'desc' : 'asc'}` });
      });
    }

    return options;
  }

  static buildDynamicOptions(parsedOptions, forcePage, defaultSorted) {
    const dynamicOptions = {};
    if (parsedOptions.size) {
      dynamicOptions.defaultPageSize = parseInt(parsedOptions.size, 10);
    }

    if (forcePage != null) {
      dynamicOptions.page = forcePage;
    } else if (parsedOptions.page) {
      dynamicOptions.defaultPage = parseInt(parsedOptions.page, 10);
    }

    if (parsedOptions.sort) {
      dynamicOptions.defaultSorted = this.convertParamSorted(parsedOptions.sort);
    } else {
      dynamicOptions.defaultSorted = defaultSorted;
    }

    return dynamicOptions;
  }

  static convertParamSorted(paramSorted) {
    let sorted = [{ id: 'occurredAt', desc: true }];
    if (paramSorted === Array) {
      console.error('multiple sort parameters are not supported yet');
    } else {
      const values = paramSorted.split(',');
      sorted = [{ id: values[0], desc: values[1] === 'desc' }];
    }
    return sorted;
  }
}

export default BaseTable;
