import { UNAUTH_USER, AUTH_USER, AUTH_ERROR } from './types';

import { AuthenticationService } from '../services';

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error
  };
}

export function signinUser({ username, password }) {
  return (dispatch) => {
    // submit email and password to server
    AuthenticationService.authenticate(username, password).then((response) => {
      localStorage.setItem('token', response.data.token);
      dispatch({ type: AUTH_USER });
    }).catch(() => {
      localStorage.removeItem('token');
      dispatch(authError('bad login info'));
    });
  };
}

export function signoutUser() {
  localStorage.removeItem('token');
  return {
    type: UNAUTH_USER
  };
}

export function dispatchAuthUser() {
  return (dispatch) => {
    dispatch({ type: AUTH_USER });
  };
}
