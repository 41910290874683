import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import WebFont from 'webfontloader';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faAngleLeft from '@fortawesome/fontawesome-free-solid/faAngleLeft';
import faAngleRight from '@fortawesome/fontawesome-free-solid/faAngleRight';

import { AUTH_USER } from './actions/types';
import reducers from './reducers';

import { Signin } from './components/Auth';
import { ForgotPassword } from './components/Auth';
import { ForgotPasswordReset } from './components/Auth';
import { Main } from './components/Main';

import { ReactTableDefaults } from 'react-table';

import { Pagination, Loading } from './components/Table';

import './i18n';
import i18next from "i18next";
import "core-js/stable";

WebFont.load({
  google: {
    families: [
      'Rubik:400,400italic,500,500italic,700',
      'Roboto:300,400,500'
    ]
  }
});

// Change the global default
Object.assign(ReactTableDefaults, {
  PaginationComponent: Pagination,
  LoadingComponent: Loading,
  defaultPageSize: 25,
  pageSizeOptions: [5, 10, 25, 50, 100],
  minRows: 0,
  noDataText: i18next.t('no-rows-found'),
  nextText: <FontAwesomeIcon icon={faAngleRight} size={'lg'}/>,
  previousText: <FontAwesomeIcon icon={faAngleLeft} size={'lg'}/>,
  pageText: ""
});

const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(reduxThunk))
);
const token = localStorage.getItem('token');

if (token) {
  store.dispatch({ type: AUTH_USER });
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>

      <Switch>
        <Route path="/signin" exact component={Signin} />
        <Route path="/forgotpassword/reset/:token" exact component={ForgotPasswordReset} />
        <Route path="/forgotpassword" exact component={ForgotPassword} />
        <Route component={Main} />
      </Switch>

    </BrowserRouter>
  </Provider>
  , document.getElementById('root'));
